<template>
<div class="py-10 flex items-center justify-center gap-3 fixed z-[9999] bottom-0">
  <Button href="https://help.homerunner.com/" target="_blank" variant="secondary-link" size="small">{{ t('do_you_need_help') }}</Button>
  <Button href="https://www.homerunner.com/privacy-policy/" target="_blank" variant="secondary-link" size="small">{{ t('privacy_policy') }}</Button>
  <Button href="https://www.homerunner.com/cookie-policy/" target="_blank" variant="secondary-link" size="small">{{ t('cookie_policy') }}</Button>
  <Button href="https://www.homerunner.com/handelsbetingelser/" target="_blank" variant="secondary-link" size="small">{{ t('business_terms') }}</Button>
  <Button href="https://www.homerunner.com/terms-of-service/" target="_blank" variant="secondary-link" size="small">{{ t('terms_of_service') }}</Button>

  <div class="text-sm text-quarterary">
    © Homerunner {{ year }}
  </div>
</div>

</template>

<script setup lang="ts">
const {t} = useI18n()

const year = new Date().getFullYear()
</script>
